html,
body {
  width: 100%;
  height: 100%;
}

.link-button {
  background-color: transparent;
}

.navbar {
  margin-bottom: 10px;
}

.card-art-xs {
  border-radius: calc(25px * 0.17);
  width: calc(480px * 0.17);
  height: calc(670px * 0.17);
  padding: 2px;
}

.card-art-small {
  border-radius: calc(25px * 0.40);
  width: calc(480px * 0.40);
  height: calc(670px * 0.40);
  padding: 2px;
}

.card-art-large {
  border-radius: 8px;
  width: calc(480px * 0.75);
  height: calc(670px * 0.75);
}

.hover-card-image {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border: 0px !important;
  color: transparent !important;
  background: transparent !important;
}

.card-row {
  margin-bottom: 5px;
}

.picked-cards-tabs {
  margin-bottom: 10px;
}

.picked-cards {
  min-height: 100px;
}

.kill-padding {
  padding: 0 0 0 0;
}

.element-separator {
  margin-top: 20px;
  margin-bottom: px;
  display: flex;
  align-items: center;
  text-align: center;
}

.center-text {
  text-align: center;
}
.center-left {
  text-align: left;
}

.element-separator::before,
.element-separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid black;
}

.element-separator::before {
  margin-right: 0.75em;
  margin-left: 1.75em;
}

.element-separator::after {
  margin-right: 1.75em;
  margin-left: 0.75em;
}

.card-list-item {
  background-color: red;
  border-radius: 5px;
  color: white;
  margin: 0px 20px 5px 20px;
}

.padding-30 {
  padding: 30px 30px 30px 30px;
}

.card-list-item-cost {
  text-align: center;
}

.card-list-item-bold {
  font-weight: bold;
}

.scroll-list {
  overflow: scroll;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.selected-card-group {
  max-height: 80vh;
  display: flex; /*added*/
  flex-direction: column;
  overflow: hidden;
}

.card-code {
  font-weight: lighter;
  font-size: small;
}

.pickable-card-item {
  display: inline-block;
}

.table-create {
  display: flex;
}

.table-create-form {
  width: 50%;
  margin: auto;
  margin-top: 50px;
}

.splash {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.support-create-form {
  width: 80%;
  margin: auto;
  margin-top: 50px;
}

.create-table-button {
  width: 100%;
}

.player-table-coulmn {
  width: 75px;
  text-align: center;
}

.admin-table-box-titles {
  margin-bottom: 10px;
}

.font-25 {
  font-size: 25px;
}

.nav-link {
  padding-right: 15px !important;
}

.force-bottom-20 {
  margin-bottom: 20px !important;
}

.hover-card-image.show {
  opacity: 1 !important;
}

.margin-10-lr {
  margin-left: 5px;
  margin-right: 5px;
}
.margin-5-b {
  margin-bottom: 5px;
}

.selected-card {
  outline: 5px solid red;
  outline-offset: -5px;
}

.card-color-fire {
  background-color: #d41;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #333 50%);
  }
}

.card-color-ice {
  background-color: #7ac;
  color: black;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #333 50%);
  }
}
.card-color-wind {
  background-color: #596;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #333 50%);
  }
}
.card-color-earth {
  background-color: #db1;
  color: black;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #333 50%);
  }
}
.card-color-lightning {
  background-color: #859;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #333 50%);
  }
}
.card-color-water {
  background-color: #57a;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #333 50%);
  }
}
.card-color-light {
  background-color: #888;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #333 50%);
  }
}
.card-color-dark {
  background-color: #333;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #333 50%);
  }
}
.card-color-multi {
  background-color: orange;
}

selector:focus {
  border: none !important;
  outline: none !important;
}
.remove-outline {
  outline: none;
}

.pickable-card-list {
  margin-bottom: 20px !important;
}

.player-name,
.arrow {
  font-family: "Lucida Sans Unicode", "Arial Unicode MS";
  font-size: 20px;
}

.passer-name {
  font-size: 20px;
}

.transparent-text {
  color: transparent;
}

.text-color-fire {
  margin-right: 1px;
  background-color: #d41;
  color: white;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #d41 50%);
    color: white;
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #7ac 50%);
    color: white;
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #596 50%);
    color: white;
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #db1 50%);
    color: white;
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #859 50%);
    color: white;
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #57a 50%);
    color: white;
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #888 50%);
    color: white;
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #d41 50%, #333 50%);
    color: white;
  }
}
.text-color-ice {
  margin-right: 1px;
  background-color: #7ac;
  color: black;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #d41 50%);
    color: white;
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #7ac 50%);
    color: white;
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #596 50%);
    color: white;
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #db1 50%);
    color: white;
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #859 50%);
    color: white;
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #57a 50%);
    color: white;
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #888 50%);
    color: white;
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #7ac 50%, #333 50%);
    color: white;
  }
}
.text-color-wind {
  margin-right: 1px;
  background-color: #596;
  color: white;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #d41 50%);
    color: white;
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #7ac 50%);
    color: white;
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #596 50%);
    color: white;
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #db1 50%);
    color: white;
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #859 50%);
    color: white;
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #57a 50%);
    color: white;
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #888 50%);
    color: white;
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #596 50%, #333 50%);
    color: white;
  }
}
.text-color-earth {
  margin-right: 1px;
  background-color: #db1;
  color: black;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #d41 50%);
    color: white;
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #7ac 50%);
    color: white;
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #596 50%);
    color: white;
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #db1 50%);
    color: white;
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #859 50%);
    color: white;
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #57a 50%);
    color: white;
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #888 50%);
    color: white;
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #db1 50%, #333 50%);
    color: white;
  }
}
.text-color-lightning {
  margin-right: 1px;
  background-color: #859;
  color: white;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #d41 50%);
    color: white;
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #7ac 50%);
    color: white;
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #596 50%);
    color: white;
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #db1 50%);
    color: white;
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #859 50%);
    color: white;
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #57a 50%);
    color: white;
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #888 50%);
    color: white;
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #859 50%, #333 50%);
    color: white;
  }
}
.text-color-water {
  margin-right: 1px;
  background-color: #57a;
  color: white;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #d41 50%);
    color: white;
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #7ac 50%);
    color: white;
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #596 50%);
    color: white;
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #db1 50%);
    color: white;
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #859 50%);
    color: white;
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #57a 50%);
    color: white;
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #888 50%);
    color: white;
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #57a 50%, #333 50%);
    color: white;
  }
}
.text-color-light {
  margin-right: 1px;
  background-color: #888;
  color: white;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #d41 50%);
    color: white;
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #7ac 50%);
    color: white;
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #596 50%);
    color: white;
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #db1 50%);
    color: white;
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #859 50%);
    color: white;
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #57a 50%);
    color: white;
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #888 50%);
    color: white;
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #888 50%, #333 50%);
    color: white;
  }
}
.text-color-dark {
  margin-right: 1px;
  background-color: #333;
  color: white;
  &.multi-fire {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #d41 50%);
  }
  &.multi-ice {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #7ac 50%);
  }
  &.multi-wind {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #596 50%);
  }
  &.multi-earth {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #db1 50%);
  }
  &.multi-lightning {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #859 50%);
  }
  &.multi-water {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #57a 50%);
  }
  &.multi-light {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #888 50%);
  }
  &.multi-dark {
    background-image: -webkit-linear-gradient(-30deg, #333 50%, #333 50%);
  }
}

.tab-text {
  font-size: 15px;
  padding-left: 8px;
  padding-right: 8px !important;
}

.float-right {
  float: right;
}

.alert-danger {
  margin: auto;
  width: 50%;
}

.main-card-flex-container {
  display: flex;
  /*min-height: 100vh;*/
  height: 84vh; /* ADDED */
  flex-direction: column;
  overflow-y: auto;
}

.overflow-y {
  overflow-y: auto;
}

.card-flex-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  max-height: 100%;
}

.card {
  -webkit-order: 0;
  -moz-order: 0;
  -ms-order: 0;
  order: 0;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-align-self: stretch;
  -moz-align-self: stretch;
  -ms-align-self: stretch;
  align-self: stretch;
}

.break {
  flex-basis: 100%;
  height: 25px;
}

.alignleft {
  float: left;
}
.alignright {
  float: right;
}

.slight-left-padding {
  margin-left: 3px;
}

.eyecon {
  padding-top: 5px;
}

.hidden-card {
  opacity: 0.3;
}

.fully-opaque {
  opacity: 1 !important;
}

.inline-deck {
  display: inline-block;
  min-width: 70%;
}

.non-public-text {
  padding-top: 100px;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 200%;
}
